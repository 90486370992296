import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const Contact = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Kontakt"
        keywords={[`borkowska`, `adwokat`, `gdańsk`, `gdansk`, `kancelaria`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h3 id="kontakt">Kontakt</h3>

          <figure className="kg-card kg-image-card kg-width-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9295.842655008664!2d18.6160154!3d54.3753449!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2bd9dc94bacc7d18!2sKancelaria%20Adwokacka%20Adwokat%20Agnieszka%20Borkowska!5e0!3m2!1spl!2spl!4v1625077555748!5m2!1spl!2spl"
              height="300px"
              width="100%"
              allowfullscreen=""
              loading="lazy"
              title="map"
            ></iframe>
            {/* <Img
              fluid={data.picture.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>adw. Agnieszka Borkowska</figcaption> */}
          </figure>

          <p>
            <strong>Kancelaria Adwokacka Adwokat Agnieszka Borkowska</strong>
            <br />
            Aleja Grunwaldzka 24 lok. 7<br />
            80 – 229 Gdańsk
            <br />
          </p>

          <p>
            Telefon: <a href="tel:+48500136402">+48 500 136 402</a>
            <br />
            Adres e–mail:{" "}
            <a href="mailto:kancelaria@adwokat-borkowska.pl">
              kancelaria@adwokat-borkowska.pl
            </a>
            <br />
            NIP: 5833408472
            <br />
            REGON: 387226448
            <br />
            Numer rachunku bankowego: 24 1050 1764 1000 0097 3414 9637
            <br />
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    picture: file(relativePath: { eq: "DOR_9750popr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ContactPage = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <Contact location={props.location} data={data} {...props} />
    )}
  />
)
export default ContactPage
